import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useMemo, useRef } from 'react';

import { useSettings } from 'hooks/useSettings';
import { HideProjectSelector } from 'layouts/HideProjectSelector';

export function Projects() {
  const { reloadSettings } = useSettings();

  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      { field: 'createdAt', headerName: 'Created at', type: 'date' },
    ],
    [],
  );

  return (
    <HideProjectSelector>
      <TablePageLayout
        title="Projects"
        actionContent={
          <Button
            sx={{ ml: 'auto' }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              tableRef.current?.openFormDialog();
            }}
          >
            Add new
          </Button>
        }
      >
        <DataTableEx
          id="projects-table"
          ref={tableRef}
          source="project"
          columns={columns}
          disableRemovedFilter
          sortBy={{ field: 'id', sort: 'desc' }}
          persistStateMode="query"
          deletable={{
            onDeleted() {
              reloadSettings();
            },
          }}
          formTitle={(isNew) => (isNew ? 'New project' : 'Edit project')}
          formDialogProps={{
            formSubmitterProps: {
              onSubmit() {
                reloadSettings();
              },
            },
            formProps: {
              defaultValues: {
                gptRewriteSystemMessage:
                  'You write short news articles for a news portal covering issues of economics, innovation and technology. News is created impersonally. The Russian language is used. The use of foreign terms is allowed. If the final text contains more than 3 sentences, then divide the text into paragraphs, adding two line breaks between paragraphs.',
                gptRewriteUserMessage:
                  'Can you summarize the content of this news? [POST_TEXT]',
              },
            },
          }}
          searchFilter={{
            inputProps: {
              placeholder: 'Search by name',
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
            }),
          }}
        >
          <FormInput name="name" label="Name" required />
          <FormInput
            multiline
            rows={4}
            name="gptRewriteSystemMessage"
            label="[GPT Rewrite] system message"
            required
          />
          <FormInput
            multiline
            rows={4}
            name="gptRewriteUserMessage"
            label="[GPT Rewrite] user message"
            required
            helperText="[POST_TEXT] is a required placeholder"
          />
        </DataTableEx>
      </TablePageLayout>
    </HideProjectSelector>
  );
}
