import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  SelectInput,
  FormGetter,
  NotificationsContext,
} from '@kirz/mui-admin';
import { Box, Button } from '@mui/material';
import { RobotHappyOutline } from 'mdi-material-ui';
import React, { useContext, useMemo, useRef } from 'react';

import { HideProjectSelector } from 'layouts/HideProjectSelector';
import { api } from 'services/api';

export function Workers() {
  const { showPrompt, showAlert } = useContext(NotificationsContext);

  const tableRef = useRef<DataTableExRef>(null);

  const handleAuth = async (workerId: number, parameters?: any) => {
    const { errorCode } = await api.authWorker({
      workerId,
      ...(parameters ?? {}),
    });

    if (errorCode === 'CODE_REQUIRED') {
      const result = await showPrompt({
        title: 'Phone verification',
        form: (
          <>
            <FormInput name="code" label="Verification code" required />
          </>
        ),
        accept: 'Continue',
        width: 500,
      });

      if (!result) {
        return;
      }

      await handleAuth(workerId, { ...(parameters ?? {}), code: result.code });
    }

    if (errorCode === 'PASSWORD_REQUIRED') {
      const result = await showPrompt({
        title: 'Password verification',
        form: (
          <>
            <FormInput
              name="password"
              label="Password"
              type="password"
              required
            />
          </>
        ),
        accept: 'Continue',
        width: 500,
      });

      if (!result) {
        return;
      }

      await handleAuth(workerId, {
        ...(parameters ?? {}),
        password: result.password,
      });
    }

    if (errorCode === 'INVALID_CODE') {
      showAlert('Invalid code', 'error');
    }

    if (errorCode === 'INVALID_PASSWORD') {
      showAlert('Invalid password', 'error');
    }

    tableRef.current?.reload();
  };

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      // {
      //   field: 'displayName',
      //   headerName: 'Name'
      // },
      {
        field: 'phoneNumber',
        headerName: 'Phone',
        type: 'phone',
      },
      {
        field: 'tdlApiId',
        headerName: 'TDLib API id',
        selector: 'tdlApiId',
        flex: 1,
      },
      {
        field: 'tdlApiHash',
        headerName: 'TDLib API hash',
        flex: 1,
      },
      {
        field: 'ownerTelegramId',
        headerName: 'Owner Telegram ID',
        flex: 1,
      },
      { field: 'createdAt', headerName: 'Created at', type: 'date' },
      {
        field: 'isLogged',
        headerName: 'Status',
        type: 'select',
        width: 100,
        items: [
          { value: false, text: 'Disabled' },
          { value: true, text: 'Enabled' },
        ],
      },
      {
        field: 'actions',
        headerName: '',
        selector: false,
        type: 'actions',
        width: 100,
        renderCell({ row }) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="contained"
                size="small"
                disabled={row.isLogged}
                onClick={() => handleAuth(row.id)}
              >
                Auth
              </Button>
            </Box>
          );
        },
      },
    ],
    [],
  );

  return (
    <HideProjectSelector>
      <TablePageLayout
        title="Workers"
        actionContent={
          <Button
            sx={{ ml: 'auto' }}
            variant="contained"
            startIcon={<RobotHappyOutline />}
            onClick={() => {
              tableRef.current?.openFormDialog();
            }}
          >
            Add worker
          </Button>
        }
      >
        <DataTableEx
          id="workers-table"
          ref={tableRef}
          source="telegramWorker"
          columns={columns}
          disableRemovedFilter
          sortBy={{ field: 'id', sort: 'asc' }}
          persistStateMode="query"
          formTitle={(isNew) => (isNew ? 'New worker' : 'Edit worker')}
          sx={{
            '& .worker-offline': {
              // backgroundColor: '#fffafa !important',
            },
          }}
          getRowClassName={({ row }) =>
            row.tdlApiId && !row.isLogged ? 'worker-offline' : ''
          }
          formDialogProps={{
            formProps: {
              defaultValues: {
                type: 'telegram',
              },
            },
            formFetcherProps: {
              onSelection(selections) {
                return selections.filter((x) => x !== 'type');
              },
            },
            formSubmitterProps: {
              preSubmit({ type, ...item }) {
                return {
                  ...item,
                };
              },
            },
          }}
          searchFilter={{
            inputProps: {
              placeholder: 'Search by name',
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
            }),
          }}
        >
          <FormGetter
            names={['id']}
            render={(x) => {
              const isNew = !x.id;

              return (
                <>
                  <SelectInput
                    name="type"
                    label="Type"
                    items={[{ text: 'Telegram', value: 'telegram' }]}
                    required
                    disabled={!isNew}
                  />
                  <FormInput
                    name="phoneNumber"
                    label="Phone number"
                    required
                    type="phone"
                    disabled={!isNew}
                  />
                  <FormInput
                    name="tdlApiId"
                    label="TDLib API id"
                    required
                    type="number"
                    disabled={!isNew}
                  />
                  <FormInput
                    name="tdlApiHash"
                    label="TDLib API hash"
                    required
                    disabled={!isNew}
                  />
                  <FormInput
                    name="ownerTelegramId"
                    label="Owner Telegram ID"
                    required
                  />
                </>
              );
            }}
          />
        </DataTableEx>
      </TablePageLayout>
    </HideProjectSelector>
  );
}
