import { BasePageLayout } from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { DealsBoard } from 'components/DealsBoard';
import DealsFilter from 'components/DealsBoard/DealsFilter';
import { DealsTable } from 'components/DealsBoard/DealsTable';
import { HideProjectSelector } from 'layouts/HideProjectSelector';

export function Deals() {
  const [displayMode, setDisplayMode] = useState<'funnel' | 'table'>(
    (new URLSearchParams(window.location.search).get('mode') as any) ??
      'funnel',
  );
  const [stagesFilter, setStagesFilter] = useState<Record<string, any>>();
  const [dealsFilter, setDealsFilter] = useState<Record<string, any>>();

  const filtersControlRef = useRef<any>();

  useEffect(() => {
    console.log(dealsFilter);
  }, [dealsFilter]);

  return (
    <HideProjectSelector>
      <BasePageLayout
        title="Deals"
        actionContent={
          <>
            <RadioGroup
              name="displayMode"
              value={displayMode}
              onChange={(x) => {
                setDisplayMode(x.target.value as any);

                const url = new URL(window.location.href);
                url.searchParams.set('mode', x.target.value);

                history.replaceState(null, '', url);
              }}
              row
              sx={{ ml: 4, mt: 0.5 }}
            >
              <FormControlLabel
                value="funnel"
                control={<Radio />}
                label="Funnel"
              />
              <FormControlLabel
                value="table"
                control={<Radio />}
                label="Table"
              />
            </RadioGroup>
            <Button
              sx={{ ml: 'auto' }}
              variant="contained"
              startIcon={<Add />}
              onClick={() => {
                filtersControlRef.current?.openNewDealDialog();
              }}
            >
              Add deal
            </Button>
          </>
        }
      >
        <Box sx={{ ml: 2, mt: 1, mb: 1 }}>
          <DealsFilter
            mode="funnel"
            // hideFunnelFilter={displayMode === 'table'}
            controlRef={filtersControlRef}
            onStagesFilterChange={(value) => {
              setStagesFilter(value);
            }}
            onDealsFilterChange={(value) => {
              setDealsFilter(value);
            }}
          />
        </Box>
        {displayMode === 'funnel' ? (
          <DealsBoard stagesFilter={stagesFilter} dealsFilter={dealsFilter} />
        ) : (
          <DealsTable dealsFilter={dealsFilter} />
        )}
      </BasePageLayout>
    </HideProjectSelector>
  );
}
