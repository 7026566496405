import {
  FormInput,
  TimeInput,
  FormDialog,
  FormDialogProps,
  Grid,
} from '@kirz/mui-admin';
import { Alert, InputAdornment } from '@mui/material';
import React, { ReactNode } from 'react';

export function EditChannelDialog({
  slots,
  ...props
}: Partial<FormDialogProps> & {
  open: boolean;
  onClose: () => void;
  slots?: { preForm?: ReactNode; postForm?: ReactNode };
}) {
  return (
    <FormDialog
      {...props}
      source="channel"
      maxWidth="sm"
      autoFocus={props?.autoFocus ?? false}
      formProps={{
        defaultValues: {
          publicationDateFrom: 60,
          publicationDateTo: 120,
          allowedIntervalFrom: '07:00',
          allowedIntervalTo: '21:00',
          ...props?.formProps?.defaultValues,
        },
      }}
    >
      {slots?.preForm ?? null}
      <FormInput name="name" label="Name" required sm={6} />
      <FormInput
        name="channelUsername"
        label="Channel username"
        placeholder="@yourchannel"
        required
        sm={6}
      />
      <FormInput name="telegramBotToken" label="Telegram bot token" required />
      <FormInput
        name="publicationDateFrom"
        label="Publication interval (from)"
        type="number"
        sm={6}
        required
        InputProps={{
          endAdornment: <InputAdornment position="end">min</InputAdornment>,
        }}
      />
      <FormInput
        name="publicationDateTo"
        label="Publication interval (to)"
        sm={6}
        required
        InputProps={{
          endAdornment: <InputAdornment position="end">min</InputAdornment>,
        }}
      />
      <Grid xs={12}>
        <Alert severity="info">Time in your local time zone</Alert>
      </Grid>
      <TimeInput
        name="allowedIntervalFrom"
        label="Allowed day interval (from)"
        required
        ampm={false}
        sm={6}
      />
      <TimeInput
        name="allowedIntervalTo"
        label="Allowed day interval (to)"
        required
        ampm={false}
        sm={6}
      />
      {slots?.postForm ?? null}
    </FormDialog>
  );
}
