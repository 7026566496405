import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  CheckboxInput,
  FormSubmitter,
  FormFetcher,
  Form,
  FormElementRef,
  SubmitButton,
} from '@kirz/mui-admin';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, InputAdornment, Tab, Tabs } from '@mui/material';
import { TextBoxPlusOutline } from 'mdi-material-ui';
import React, { useMemo, useRef, useState } from 'react';

import { useAppState } from 'hooks/useAppState';
import { useTranslation } from 'hooks/useTranslation';

export function AutoFilter() {
  const { selectedProjectId } = useAppState();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<'stopwords' | 'gpt'>(
    'stopwords',
  );

  const formRef = useRef<FormElementRef>(null);
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'value',
        headerName: 'Value',
        flex: 1,
      },
      {
        field: 'caseSensitive',
        headerName: 'Case sensitive',
        type: 'boolean',
        flex: 1,
      },
      {
        field: 'matchesCount',
        headerName: 'Matches',
        flex: 1,
      },
      {
        field: 'monthMatchesCount',
        headerName: 'Matches this month',
        flex: 1,
      },
      { field: 'createdAt', headerName: 'Created at', type: 'date' },
    ],
    [],
  );

  const gptColumns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'text',
        headerName: 'Topic',
        flex: 1,
      },
      { field: 'createdAt', headerName: 'Created at', type: 'date' },
    ],
    [],
  );

  return (
    <TablePageLayout
      key={selectedProjectId}
      title="Auto Filter"
      actionContent={
        <SubmitButton
          sx={{ ml: 'auto' }}
          variant="contained"
          loadingPosition="start"
          startIcon={<SaveIcon />}
          formRef={formRef}
          activateOnDirty
        >
          Save
        </SubmitButton>
      }
    >
      <FormSubmitter
        source="project"
        entityId={{ id: { _eq: selectedProjectId } }}
      >
        <FormFetcher
          source="project"
          entityId={{ id: { _eq: selectedProjectId } }}
        >
          <Form ref={formRef} sx={{ pt: 1 }}>
            <FormInput
              name="minPostLength"
              type="number"
              md={3}
              label="Min post length"
              placeholder="Filter disabled"
              helperText="Small posts will be rejected"
            />
            <FormInput
              name="postExpirationTime"
              type="number"
              md={3}
              label="Post expiration time"
              placeholder="Filter disabled"
              helperText="Old posts will be rejected"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">days</InputAdornment>
                ),
              }}
            />
            <FormInput
              name="gptAutofilterExpectedResponse"
              label="[GPT Autofilter] expected response"
              required
              md={6}
            />
            <FormInput
              multiline
              rows={2}
              name="gptAutofilterSystemMessage"
              label="[GPT Autofilter] system message"
              required
              md={6}
              sx={{ mt: 3 }}
            />
            <FormInput
              multiline
              rows={2}
              name="gptAutofilterUserMessage"
              label="[GPT Autofilter] user message"
              required
              helperText="[TOPICS_LIST] and [POST_TEXT] are required placeholders"
              md={6}
              sx={{ mt: 3 }}
            />
          </Form>
        </FormFetcher>
      </FormSubmitter>
      <Tabs
        value={selectedTab}
        onChange={(e, v) => setSelectedTab(v)}
        sx={{
          mt: { xs: 4, md: 7, lg: 7 },
          mb: 2,
          minHeight: '30px',
          '& .MuiTab-root': {
            pt: 0,
            pb: 0,
            minHeight: '30px',
          },
        }}
      >
        <Tab label="Stopwords" value="stopwords" />
        <Tab label="GPT banned topics" value="gpt" />
      </Tabs>

      <Box
        sx={{
          minHeight: 400,
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {selectedTab === 'stopwords' ? (
          <DataTableEx
            id="stopwords-table"
            ref={tableRef}
            source="stopword"
            columns={columns}
            sortBy={{ field: 'id', sort: 'desc' }}
            persistStateMode="query"
            formTitle={(isNew) => (isNew ? 'New stopword' : 'Edit stopword')}
            formDialogProps={{
              formProps: {
                defaultValues: {
                  caseSensitive: false,
                },
              },
              formSubmitterProps: {
                preSubmit: (item) => ({
                  ...item,
                  projectId: selectedProjectId,
                }),
              },
            }}
            selectProps={{
              filter: {
                projectId: { _eq: selectedProjectId },
              },
            }}
            searchFilter={{
              inputProps: {
                placeholder: 'Search by value',
              },
              filter: (search) => ({
                _or: search.flatMap((str) => [
                  { value: { _ilike: `%${str}%` } },
                ]),
              }),
              slots: {
                afterSearch: (
                  <Button
                    sx={{ ml: 2, flexShrink: 0 }}
                    variant="contained"
                    startIcon={<TextBoxPlusOutline />}
                    onClick={async () => {
                      tableRef.current?.openFormDialog();
                    }}
                  >
                    {t('Add stopword')}
                  </Button>
                ),
              },
            }}
          >
            <FormInput
              name="value"
              label="Value"
              placeholder="Enter text you would not like to see in your detected posts"
              required
              helperText="Regular expression supported"
            />
            <CheckboxInput name="caseSensitive" label="Case sensitive?" />
          </DataTableEx>
        ) : selectedTab === 'gpt' ? (
          <DataTableEx
            id="gpt-banned-topics-table"
            ref={tableRef}
            source="gptBannedTopic"
            columns={gptColumns}
            sortBy={{ field: 'id', sort: 'desc' }}
            persistStateMode="query"
            formTitle={(isNew) =>
              isNew ? 'New banned topic' : 'Edit banned topic'
            }
            formDialogProps={{
              formSubmitterProps: {
                preSubmit: (item) => ({
                  ...item,
                  projectId: selectedProjectId,
                }),
              },
            }}
            selectProps={{
              filter: {
                projectId: { _eq: selectedProjectId },
              },
            }}
            searchFilter={{
              inputProps: {
                placeholder: 'Search by text',
              },
              filter: (search) => ({
                _or: search.flatMap((str) => [
                  { text: { _ilike: `%${str}%` } },
                ]),
              }),
              slots: {
                afterSearch: (
                  <Button
                    sx={{ ml: 2, flexShrink: 0 }}
                    variant="contained"
                    startIcon={<TextBoxPlusOutline />}
                    onClick={async () => {
                      tableRef.current?.openFormDialog();
                    }}
                  >
                    Add topic
                  </Button>
                ),
              },
            }}
          >
            <FormInput name="text" label="Topic" required />
          </DataTableEx>
        ) : null}
      </Box>
    </TablePageLayout>
  );
}
