import {
  ConfigurationContext,
  DateInput,
  NotificationsContext,
  SelectInput,
  TimeInput,
  Grid,
} from '@kirz/mui-admin';
import { Alert } from '@mui/material';
import dayjs from 'dayjs';
import React, { useCallback, useContext } from 'react';

import { EditChannelDialog } from 'components/EditChannelDialog';

import { useAppState } from './useAppState';

export function usePostManager() {
  const { showPrompt } = useContext(NotificationsContext);
  const { hasura } = useContext(ConfigurationContext);
  const { selectedProjectId } = useAppState();

  const showPublicationParametersDialog = useCallback(
    async (projectId: string | number) => {
      const channelSelection = `id name publicationDateFrom publicationDateTo allowedIntervalFrom allowedIntervalTo project { posts (where:{ _or:[{ isRejected: { _eq: false }, projectId: { _eq: ${projectId} }, publicationDate: { _isNull: false }, isPublished: { _eq: false }, }, { projectId: { _eq: ${projectId} }, isPublished: { _eq: true } }] },orderBy: {publicationDate:DESC}, limit:1) { publicationDate } }`;

      let channels = await hasura.request({
        type: 'query',
        where: {
          projectId: { _eq: projectId },
          isRemoved: { _eq: false },
        },
        orderBy: [{ id: 'ASC' }],
        selection: channelSelection,
        source: 'channel',
      });

      if (!channels.length) {
        const newChannel = await showPrompt({
          dialog: ({ open, onAccept, onClose }) => (
            <EditChannelDialog
              open={open}
              title="New channel"
              formSubmitterProps={{
                preSubmit(item) {
                  return { ...item, projectId: selectedProjectId };
                },
                selection: [channelSelection],
              }}
              onClose={onClose}
              onSubmit={(item) => {
                onAccept(item);
              }}
              slots={{
                preForm: (
                  <Grid xs={12}>
                    <Alert severity="warning">
                      To publish the post, enter the information about your
                      channel.
                    </Alert>
                  </Grid>
                ),
              }}
            />
          ),
        });

        if (!newChannel) {
          return null;
        }

        channels = [newChannel];
      }

      let selectedChannel = null;
      if (channels.length > 1) {
        const channelSelectionForm = await showPrompt({
          title: 'Select channel',
          form: (
            <>
              <SelectInput
                label="Channel"
                name="channelId"
                required
                items={channels.map((x: any) => ({
                  text: x.name,
                  value: x.id,
                }))}
              />
            </>
          ),
          formProps: {
            defaultValues: {
              channelId: channels[0]?.id,
            },
          },
          width: 350,
          accept: 'Next',
          cancel: 'Cancel',
        });

        if (!channelSelectionForm) {
          return null;
        }

        selectedChannel = channels.find(
          (x: any) => x.id === channelSelectionForm.channelId,
        );
      } else {
        selectedChannel = channels[0];
      }

      const {
        publicationDateFrom,
        publicationDateTo,
        allowedIntervalFrom,
        allowedIntervalTo,
      } = selectedChannel;

      const lastPostDate = selectedChannel.project.posts[0]?.publicationDate
        ? new Date(
            Math.max(
              new Date(
                selectedChannel.project.posts[0]?.publicationDate,
              ).valueOf(),
              new Date().valueOf(),
            ),
          ).toISOString()
        : new Date().toISOString();

      const getNextRandomDate = (startDate: string) => {
        return dayjs(startDate).add(
          Math.floor(
            publicationDateFrom +
              Math.random() * (publicationDateTo - publicationDateFrom),
          ),
          'minutes',
        );
      };

      let nextRandomDate = getNextRandomDate(lastPostDate);

      const thresholdStartDate = dayjs(
        `${dayjs(nextRandomDate)
          .startOf('day')
          .format('YYYY-MM-DD')} ${allowedIntervalFrom}`,
        'YYYY-MM-DD HH:mm',
      );

      const thresholdEndDate = dayjs(
        `${dayjs(nextRandomDate)
          .startOf('day')
          .format('YYYY-MM-DD')} ${allowedIntervalTo}`,
        'YYYY-MM-DD HH:mm',
      );

      if (nextRandomDate.valueOf() > thresholdEndDate.valueOf()) {
        nextRandomDate = getNextRandomDate(
          dayjs(
            `${dayjs(nextRandomDate)
              .startOf('day')
              .add(1, 'day')
              .format('YYYY-MM-DD')} ${allowedIntervalFrom}`,
            'YYYY-MM-DD HH:mm',
          ).toISOString(),
        );
      } else if (nextRandomDate.valueOf() < thresholdStartDate.valueOf()) {
        nextRandomDate = getNextRandomDate(thresholdStartDate.toISOString());
      }

      const formData = await showPrompt({
        title: 'Publication',
        form: (
          <>
            <SelectInput
              label="Channel"
              name="channelId"
              required
              items={channels.map((x: any) => ({
                text: x.name,
                value: x.id,
              }))}
              readOnly
            />
            <Grid xs={12}>
              <Alert severity="info">
                Date and time in your local timezone (
                {Intl.DateTimeFormat().resolvedOptions().timeZone})
              </Alert>
            </Grid>
            <DateInput name="date" label="Date" required sm={6} />
            <TimeInput
              sm={6}
              clearable={false}
              name="time"
              label="Time"
              required
              ampm={false}
            />
          </>
        ),
        formProps: {
          defaultValues: {
            channelId: selectedChannel.id,
            date: nextRandomDate.format('YYYY-MM-DD'),
            time: nextRandomDate.format('HH:mm'),
          },
        },
        width: 350,
        accept: 'Confirm',
        cancel: 'Cancel',
      });

      if (!formData) {
        return null;
      }

      return {
        publicationChannelId: formData.channelId,
        publicationDate: dayjs(
          `${formData.date}T${formData.time}:00`,
          'YYYY-MM-DDTHH:mm:ss',
        ).toISOString(),
      };
    },
    [hasura, selectedProjectId],
  );

  return { showPublicationParametersDialog };
}
