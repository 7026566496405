import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  SelectInput,
  NotificationsContext,
  FormGetter,
  HiddenInput,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { TextBoxPlusOutline } from 'mdi-material-ui';
import React, { useContext, useMemo, useRef } from 'react';

import { TextReplacersTable } from 'components/TextReplacersTable';
import { SourceTypes } from 'constants/other';
import { useAppState } from 'hooks/useAppState';
import { api } from 'services/api';
import { useTranslation } from 'hooks/useTranslation';

export function Sources() {
  const { selectedProjectId } = useAppState();
  const { showAlert } = useContext(NotificationsContext);
  const { t } = useTranslation();

  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'avatarId',
        headerName: t('Icon'),
        selector: 'avatar { id contentType name extension }',
        type: 'file',
        sortable: false,
        hideText: true,
        width: 80,
        valueGetter({ row }) {
          return row.avatar?.id;
        },
        fetchMetadata(row) {
          if (!row.avatar) {
            return null;
          }

          return row.avatar;
        },
      },
      {
        field: 'name',
        headerName: t('Name'),
        flex: 1,
      },
      {
        field: 'channelUniqueName',
        headerName: t('Username'),
        flex: 1,
      },
      {
        field: 'type',
        headerName: t('Type'),
        width: 100,
        type: 'select',
        items: SourceTypes,
      },
      { field: 'createdAt', headerName: t('Created at'), type: 'date' },
    ],
    [],
  );

  return (
    <TablePageLayout
      title={t('Sources')}
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<TextBoxPlusOutline />}
          onClick={async () => {
            tableRef.current?.openFormDialog();
          }}
        >
          {t('Add source')}
        </Button>
      }
    >
      <DataTableEx
        id="sources-table"
        ref={tableRef}
        source="source"
        columns={columns}
        disableRemovedFilter
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        formTitle={(isNew) => (isNew ? t('New source') : t('Edit source'))}
        formDialogProps={{
          formProps: {
            defaultValues: {
              type: 'telegram',
            },
          },
          entityIdResolver(entity) {
            return {
              id: { _eq: entity.id },
              projectId: { _eq: selectedProjectId },
            };
          },
          formSubmitterProps: {
            async preSubmit({ id, ...item }) {
              if (id) {
                return item;
              }

              const { type, channel } = item;
              const chat = await api.searchChannel(channel);

              if (!chat) {
                showAlert('Channel not found', 'error');
                return null;
              }

              const avatar =
                chat.photo?.big.id &&
                (await api.downloadFile(
                  chat.photo.big.id,
                  chat.tdlib_client_id,
                ));

              return {
                type,
                tgChatId: chat.id,
                avatarId: avatar?.id,
                name: chat.title,
                channelUniqueName: `@${channel
                  .replace(`https://t.me/`, '')
                  .replace(`http://t.me/`, '')
                  .replace(/@/g, '')
                  .trim()}`,
                projectId: selectedProjectId,
              };
            },
          },
        }}
        selectProps={{
          filter: {
            projectId: { _eq: selectedProjectId },
            type: { _neq: 'manual' },
          },
        }}
        searchFilter={{
          inputProps: {
            placeholder: t('Search by name'),
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
          }),
        }}
        getRowId={(x) => `${selectedProjectId}-${x.id}`}
      >
        <HiddenInput name="id" />
        <FormGetter
          names={['id']}
          render={({ id: sourceId }) =>
            !sourceId ? (
              <>
                <SelectInput
                  name="type"
                  label="Type"
                  items={SourceTypes}
                  required
                  formFetcherValueResolver={null}
                  formSubmitterValueResolver={null}
                />
                <FormInput
                  name="channel"
                  label={t('Channel')}
                  placeholder={t(
                    'Enter full channel username (e.g. @prodneupal)',
                  )}
                  required
                  formFetcherValueResolver={null}
                  formSubmitterValueResolver={null}
                />
              </>
            ) : (
              <>
                <FormInput name="name" label="Name" required />
                <FormInput name="channelUniqueName" label="Username" required />
                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle1">Text replacers</Typography>
                    <Button
                      startIcon={<Add />}
                      variant="outlined"
                      size="small"
                      sx={{ mx: 2, whiteSpace: 'pre' }}
                      onClick={() => {
                        tableRef.current?.openFormDialog();
                      }}
                    >
                      Add new
                    </Button>
                  </Box>
                  <TextReplacersTable
                    id="text-replacers-table"
                    ref={tableRef}
                    sx={{
                      mt: 1,
                      minHeight: 200,
                    }}
                    skeletonRowsCount={1}
                    selectProps={{
                      filter: {
                        projectId: { _eq: selectedProjectId },
                        sourceId: { _eq: sourceId },
                      },
                    }}
                    formDialogProps={{
                      formSubmitterProps: {
                        preSubmit(item) {
                          return {
                            ...item,
                            projectId: selectedProjectId,
                            sourceId,
                          };
                        },
                      },
                    }}
                  />
                </Box>
              </>
            )
          }
        />
      </DataTableEx>
    </TablePageLayout>
  );
}
