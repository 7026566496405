import { FormDialog, FormDialogProps, FormInput, Grid } from '@kirz/mui-admin';
import { Typography } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import React, { ReactNode } from 'react';

export type EditProjectDialogProps = Partial<FormDialogProps> & {
  open: boolean;
  onClose: () => void;
  formHeader?: ReactNode;
};

export function EditProjectDialog({
  entityId,
  formHeader,
  ...props
}: EditProjectDialogProps) {
  const { t } = useTranslation();
  return (
    <FormDialog
      {...props}
      source="project"
      entityId={entityId}
      maxWidth="sm"
      autoFocus={false}
      title={entityId ? t('Edit project') : t('Create new project')}
    >
      {formHeader && <Grid xs={12}>{formHeader}</Grid>}
      <Typography variant="body1" gutterBottom>
        {t('Create project description')}
      </Typography>
      <FormInput name="name" label={t('Project name')} required />
    </FormDialog>
  );
}
