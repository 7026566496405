import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  ConfigurationContext,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { HideProjectSelector } from 'layouts/HideProjectSelector';

export function Funnels() {
  const [sortValues, setSortValues] = useState<number[] | null>(null);
  const tableRef = useRef<DataTableExRef>(null);
  const { hasura } = useContext(ConfigurationContext);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'sort',
        type: 'sort',
        sortKey: 'sort',
        rowsSortValues: sortValues,
        onSortChange: async (direction, row, targetRowSort) => {
          const source = 'Funnel';
          const { id } = row;

          await hasura.request({
            type: 'custom',
            query: `
              mutation ChangeSort($where1: ${source}BoolExp!, $set1: ${source}SetInput!, $where2: ${source}BoolExp!, $set2: ${source}SetInput!) {
                result2: update${source}(where: $where2, _set: $set2) {
                  affected_rows
                }
                result1: update${source}(where: $where1, _set: $set1) {
                  affected_rows
                }
              }
            `,
            variables: {
              where1: { id: { _eq: id } },
              set1: { sort: targetRowSort },
              where2: {
                sort: { _eq: targetRowSort },
              },
              set2: { sort: row.sort },
            },
          });

          tableRef.current?.reload();
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        sortable: false,
      },
      {
        field: 'stagesAggregate { aggregate { *count* } }',
        headerName: 'Stages count',
        flex: 1,
        type: 'relationship',
        fetchRemoved: false,
        sortable: false,
      },
      {
        field: 'dealsAggregate { aggregate { *count* } }',
        headerName: 'Deals count',
        flex: 1,
        type: 'relationship',
        fetchRemoved: false,
        sortable: false,
      },
      {
        field: 'createdAt',
        headerName: 'Created at',
        type: 'date',
        sortable: false,
      },
    ],
    [sortValues],
  );

  useEffect(() => {
    (async () => {
      const items = await hasura.request({
        type: 'query',
        source: 'funnel',
        selection: 'sort',
      });

      setSortValues(items.map((x: any) => x.sort));
    })();
  }, []);

  return (
    <HideProjectSelector>
      <TablePageLayout
        title="Funnels"
        actionContent={
          <Button
            sx={{ ml: 'auto' }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              tableRef.current?.openFormDialog();
            }}
          >
            Add new
          </Button>
        }
      >
        <DataTableEx
          id="funnels-table"
          ref={tableRef}
          source="funnel"
          columns={columns}
          disableRemovedFilter
          sortBy={{ field: 'sort', sort: 'asc' }}
          persistStateMode="query"
          editPageUrl="/funnels"
          formTitle={(isNew) => (isNew ? 'New funnel' : 'Edit funnel')}
          searchFilter={{
            inputProps: {
              placeholder: 'Search by name',
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
            }),
          }}
          deletable={{
            isEnabled(row) {
              return !row.dealsAggregate.aggregate.count;
            },
            onDeleted: (row) => {
              setSortValues(sortValues!.filter((x) => x !== row.sort));
            },
          }}
        >
          <FormInput name="name" label="Name" required />
        </DataTableEx>
      </TablePageLayout>
    </HideProjectSelector>
  );
}
