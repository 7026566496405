import {
  AttachmentsZone,
  ConfigurationContext,
  FormGetter,
  FormPageLayout,
  FormSetter,
  Grid,
  HiddenInput,
  NotificationsContext,
  SubmitButton,
  useNavigate,
} from '@kirz/mui-admin';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { Close, RobotHappy, TextBoxOutline, Web } from 'mdi-material-ui';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { TelegramTextEditor } from 'components/TelegramTextEditor';
import { usePostManager } from 'hooks/usePostManager';
import { HideProjectSelector } from 'layouts/HideProjectSelector';

function ActionButtons({ submitActionRef, ...props }: any) {
  const { getValues } = useFormContext();

  if (!props.activateOnDirty) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 'auto' }}>
      <SubmitButton
        formRef={props.formRef}
        onClick={() => {
          submitActionRef.current = 'reject';
        }}
        loading={false}
        startIcon={<Close />}
        activateOnDirty={false}
        color="error"
      >
        Reject
      </SubmitButton>
      <SubmitButton
        formRef={props.formRef}
        onClick={() => {
          submitActionRef.current = 'save';
        }}
        loading={false}
        startIcon={<Save />}
        activateOnDirty
      >
        Save
      </SubmitButton>
      <SubmitButton
        formRef={props.formRef}
        variant="contained"
        onClick={() => {
          submitActionRef.current = 'publish';
        }}
        loading={false}
        startIcon={<Web />}
        activateOnDirty={false}
      >
        {!getValues('publicationChannelId')
          ? 'Schedule publication'
          : 'Edit publication '}
      </SubmitButton>
    </Stack>
  );
}

export function Post() {
  const { postId, projectId } = useParams() as Record<string, string>;
  const navigate = useNavigate();
  const { showAlert } = useContext(NotificationsContext);
  const { rest } = useContext(ConfigurationContext);
  const { showPublicationParametersDialog } = usePostManager();
  const [isRewriting, setIsRewriting] = useState(false);

  const submitActionRef = useRef<'reject' | 'save' | 'publish'>();

  const rewritePostWithGpt = useCallback(
    async (setValue: any) => {
      try {
        setIsRewriting(true);

        const {
          data: { text },
        } = await rest.client.post(
          `/posts/${projectId}/${postId}/gpt-rewrite`,
          {
            text:
              document.querySelector('#editable-post-content-wrapper .tiptap')
                ?.textContent ?? '',
          },
        );

        setValue('text', null, { shouldDirty: true });
        setTimeout(() => {
          setValue('text', text, { shouldDirty: true });
        }, 0);

        showAlert('GPT rewrite completed');
      } catch {
        showAlert('Unexpected error', 'error');
      } finally {
        setIsRewriting(false);
      }
    },
    [postId, projectId],
  );

  return (
    <HideProjectSelector>
      <FormPageLayout
        source="post"
        getEntityId={useCallback(
          () => ({
            id: { _eq: parseInt(postId, 10) },
            projectId: { _eq: parseInt(projectId, 10) },
          }),
          [],
        )}
        breadcrumbs={[
          { text: 'Posts', icon: TextBoxOutline, href: '/posts' },
          (item) => {
            return {
              text: `Post #${item.id}`,
              copyOnClick: true,
            };
          },
        ]}
        slots={{
          SubmitButton: (props: any) => (
            <ActionButtons submitActionRef={submitActionRef} {...props} />
          ),
        }}
        // actionContent={<SaveButton />}
        defaultRoute="/posts"
        formSubmitterProps={{
          async preSubmit(item) {
            if (submitActionRef.current === 'save') {
              return item;
            }

            if (submitActionRef.current === 'reject') {
              return {
                ...item,
                isRejected: true,
                rejectionReason: 'manual',
                rejectedAt: new Date().toISOString(),
                publicationChannelId: null,
                publicationDate: null,
              };
            }

            const publicationInfo = await showPublicationParametersDialog(
              projectId,
            );

            if (!publicationInfo) {
              return null;
            }

            return {
              ...item,
              ...publicationInfo,
            };
          },
          onSubmit() {
            if (submitActionRef.current !== 'save') {
              setTimeout(() => {
                navigate('/posts');
              }, 100);
            }
          },
        }}
        formFetcherProps={{
          onFetch(item) {
            return {
              ...item,
            };
          },
        }}
        formProps={{ dirtySubmit: false }}
      >
        <Grid container spacing={3}>
          <FormSetter
            render={(setValues) => (
              <>
                <Typography
                  variant="body2"
                  sx={{ ml: 2, mb: -0.5, mt: 2 }}
                  fontWeight="500"
                >
                  Attachments
                </Typography>
                <AttachmentsZone
                  xs={12}
                  name="postId"
                  entityId={{
                    postId,
                    projectId,
                  }}
                  displayMode="simple"
                  attachmentsTypes={[{ value: 'file', text: 'Attachments' }]}
                  gridProps={{
                    spacing: 1,
                  }}
                  dropzoneProps={{
                    minSize: 1,
                    maxSize: 50 * 1024 * 1024,
                    onDropRejected: () => {
                      showAlert('File size exceeds 50 MB', 'error');
                    },
                  }}
                />

                <FormGetter
                  names={['text']}
                  render={({ text }) => (
                    <>
                      <Grid xs={12} md={6} id="editable-post-content-wrapper">
                        <TelegramTextEditor
                          content={text}
                          editable
                          onChange={(value) => {
                            setValues('text', value, { shouldDirty: true });
                          }}
                        />
                      </Grid>
                    </>
                  )}
                />

                <FormGetter
                  names={['originalText']}
                  render={({ originalText }) => (
                    <>
                      <Grid xs={12} md={6}>
                        <TelegramTextEditor
                          content={originalText}
                          editable={false}
                          slotBefore={
                            <Box
                              sx={{
                                height: '36px',
                                bgcolor: 'rgb(16 24 39 / 7%)',
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: 2,
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  opacity: 0.8,
                                  fontWeight: '500',
                                }}
                              >
                                Original post
                              </Typography>
                            </Box>
                          }
                        />
                      </Grid>
                    </>
                  )}
                />
                <Grid>
                  <LoadingButton
                    variant="outlined"
                    startIcon={<RobotHappy />}
                    loading={isRewriting}
                    onClick={() => {
                      rewritePostWithGpt(setValues);
                    }}
                  >
                    Rewrite with ChatGPT
                  </LoadingButton>
                </Grid>
              </>
            )}
          />

          <HiddenInput name="publicationChannelId" />
          <HiddenInput name="publicationDate" />
          <HiddenInput name="text" />
          <HiddenInput name="originalText" formSubmitterValueResolver={null} />
        </Grid>
      </FormPageLayout>
    </HideProjectSelector>
  );
}
