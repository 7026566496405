import { NavigationContext, SelectInput } from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar as MuiAppBar,
  Box,
  IconButton,
  Toolbar,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import { FolderOutline } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';

import { TOOLBAR_HEIGHT } from 'constants/theme';
import { useAppState } from 'hooks/useAppState';
import { useSettings } from 'hooks/useSettings';

import { AppBarAccount } from './AppBarAccount';
import { EditProjectDialog } from './EditProjectDialog';
import { useTranslation } from 'hooks/useTranslation';

type Props = {
  onDrawerToggleClick: () => void;
};

export function AppBar({ onDrawerToggleClick }: Props) {
  let { projects, selectedProjectId, setSelectedProjectId } = useAppState();
  const { t } = useTranslation();
  const { reloadSettings } = useSettings();
  const { setNavbarContainer } = useContext(NavigationContext);
  // if(projects == undefined) {
  //   projects = [ { id: -1, name: 'N/A', createdAt: new Date().toISOString()}]
  // }
  const [isNewProjectDialogOpened, setIsNewProjectDialogOpened] =
    useState(false);

  return (
    <>
      <MuiAppBar
        position="absolute"
        sx={{
          left: { xs: 0 },
          width: { xs: '100%' },
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: 'background.paper',
          height: TOOLBAR_HEIGHT,
          color: (theme) => theme.palette.text.primary,
        }}
      >
        <Toolbar
          sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
        >
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2, display: { xs: 'flex', lg: 'none' } }}
            onClick={onDrawerToggleClick}
          >
            <MenuIcon />
          </IconButton>
          <Box id="project-selector">
            <SelectInput
              sx={{
                '& .MuiFormHelperText-root': {
                  display: 'none',
                },
                minWidth: 250,
                mr: 3,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FolderOutline />
                  </InputAdornment>
                ),
              }}
              helperText={null}
              name="project"
              value={selectedProjectId}
              onChange={(e) => {
                if (!e.target.value) {
                  return;
                }

                setSelectedProjectId(parseInt(e.target.value, 10));
              }}
              size="small"
              items={[
                ...projects.map((x) => ({
                  value: x.id,
                  text: x.name,
                })),
                {
                  value: '_new',
                  component: (
                    <MenuItem
                      key="_new"
                      onClick={() => {
                        setIsNewProjectDialogOpened(true);
                      }}
                      sx={{ opacity: 0.8 }}
                    >
                      <Add sx={{ mr: 1 }} />
                      {t('New project')}
                    </MenuItem>
                  ),
                },
              ]}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              pl: 0,
              pr: 5,
              display: 'flex',
              alignItems: 'center',
            }}
            ref={(ref) => {
              setNavbarContainer(ref as any);
            }}
          />
          <AppBarAccount />
        </Toolbar>
      </MuiAppBar>
      <EditProjectDialog
        open={isNewProjectDialogOpened}
        onSubmit={async (item) => {
          await reloadSettings();
        }}
        onClose={async () => {
          setIsNewProjectDialogOpened(false);
        }}
      />
    </>
  );
}
