import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  NotificationsContext,
  ConfigurationContext,
  Grid,
  FormInput,
} from '@kirz/mui-admin';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { CloseBox, OpenInNew, TextBoxPlusOutline } from 'mdi-material-ui';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';

import { useAppState } from 'hooks/useAppState';
import { useTranslation } from 'hooks/useTranslation';

export function Posts() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { selectedProjectId } = useAppState();
  const { showAlert, showPrompt } = useContext(NotificationsContext);
  const [selectedTab, setSelectedTab] = useState<string>('queue');
  const { rest, hasura } = useContext(ConfigurationContext);
  const tableRef = useRef<DataTableExRef>(null);
  const firstEntityRef = useRef<any | null>(null);
  const selectedTabRef = useRef(selectedTab);

  useHotkeys(
    'r, к',
    async () => {
      if (selectedTab !== 'queue') {
        return;
      }

      if (firstEntityRef.current) {
        handleActionPress('reject', firstEntityRef.current);
        firstEntityRef.current = null;
      }
    },
    {},
    [selectedTab],
  );

  useHotkeys(
    'k, л',
    async () => {
      if (selectedTab !== 'queue') {
        return;
      }

      if (firstEntityRef.current) {
        navigate(
          `/projects/${selectedProjectId}/posts/${firstEntityRef.current.id}`,
          {},
        );
        firstEntityRef.current = null;
      }
    },
    {},
    [selectedTab],
  );

  const handleActionPress = async (
    decision: 'accept' | 'reject',
    post: any,
  ) => {
    await hasura.request({
      type: 'mutation',
      source: 'post',
      action: 'update',
      where: {
        id: { _eq: post.id },
        projectId: { _eq: selectedProjectId },
      },
      set:
        decision === 'accept'
          ? {}
          : {
              isRejected: true,
              rejectionReason: 'manual',
              rejectedAt: new Date().toISOString(),
              publicationChannelId: null,
              publicationDate: null,
            },
    });

    if (decision === 'accept') {
      showAlert(t('Post accepted'), 'success');
    } else {
      showAlert(t('Post rejected'));
    }

    tableRef.current?.reload();
    return true;
  };

  const addStopword = async (postId?: number, closeDialog?: () => void) => {
    const selectedText = window.getSelection()?.toString() ?? '';

    const rejectCurrentPost = postId && !!selectedText;

    const result = await showPrompt({
      title: t('Add stopword'),
      form: (
        <>
          {rejectCurrentPost && (
            <Grid xs={12}>
              <Alert severity="warning">
                {t('This will automatically decline current post')}
              </Alert>
            </Grid>
          )}
          <FormInput name="stopword" label={t('Stopword')} required />
        </>
      ),
      formProps: {
        defaultValues: {
          stopword: selectedText,
        },
      },
      width: 400,
      accept: t('Create'),
      cancel: t('Cancel'),
    });

    if (!result) {
      return;
    }

    await hasura.request({
      type: 'mutation',
      source: 'stopword',
      action: 'insertOne',
      item: {
        projectId: selectedProjectId,
        value: result.stopword,
        caseSensitive: true,
      },
    });

    if (rejectCurrentPost) {
      await hasura.request({
        type: 'mutation',
        source: 'post',
        action: 'update',
        where: {
          id: { _eq: postId },
          projectId: { _eq: selectedProjectId },
        },
        set: {
          isRejected: true,
          rejectionReason: `stopword:${selectedText}`,
          rejectedAt: new Date().toISOString(),
        },
      });

      tableRef.current?.reload();
    }

    closeDialog?.();
    showAlert(t('Stopword added'));
  };

  const createEmptyPost = async () => {
    try {
      const result = await rest.client.post(
        `/posts/${selectedProjectId}/createEmptyPost`,
        {},
      );
      const newPostId = result.data.id;
      showAlert(`New post has been created. Post ID — ${newPostId}`, 'info');
      navigate(`/projects/${selectedProjectId}/posts/${newPostId}`);
    } catch {
      showAlert('Unexpected error', 'error');
    }
  };

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'fileId',
        headerName: t('File'),
        type: 'file',
        hideText: true,
        selector: 'files(limit:1) { id contentType name extension }',
        width: 80,
        sortable: false,
        valueGetter({ row }) {
          return row.files[0]?.id;
        },
        fetchMetadata(row) {
          if (!row.files?.[0]) {
            return null;
          }

          return {
            ...row.files?.[0],
            thumbnail: `/api/v1/files/w_100,h_100/${row.files?.[0].id}`,
          };
        },
      },
      {
        field: 'plainText',
        headerName: t('Text'),
        renderCell({ value }) {
          if (!value?.trim().length) {
            return '—';
          }

          return (
            <Box
              component="div"
              sx={{
                textWrap: 'wrap',
                height: '100%',
                py: 1,
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{ maxHeight: '100%' }}
                dangerouslySetInnerHTML={{
                  __html: value as string,
                }}
              />
            </Box>
          );
        },
        tabs: ['queue'],
      },
      {
        field: 'text',
        headerName: t('Text'),
        renderCell({ value }) {
          if (!value?.trim().length) {
            return '—';
          }

          return (
            <Box
              component="div"
              sx={{
                textWrap: 'wrap',
                height: '100%',
                py: 1,
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{ maxHeight: '100%', '& p': { margin: 0 } } as any}
                dangerouslySetInnerHTML={{
                  __html: value as string,
                }}
              />
            </Box>
          );
        },
        tabs: ['ready', 'rejected', 'published'],
      },
      {
        field: 'rejectionReason',
        headerName: t('Rejection reason'),
        width: 200,
        tabs: ['rejected'],
      },
      {
        headerName: t('Source'),
        field: 'source',
        width: 120,
        type: 'relationship',
        selector: 'source { *name* }',
      },
      {
        field: 'publicationDate',
        selector: 'postedAt rejectedAt publicationDate',
        headerName:
          selectedTab === 'queue'
            ? t('Created at')
            : selectedTab === 'rejected'
            ? t('Rejected at')
            : selectedTab === 'ready'
            ? t('Publication date')
            : t('Published at'),
        type: 'dateTime',
        valueGetter({ row }) {
          if (selectedTabRef.current === 'queue') {
            return row.postedAt;
          }

          if (selectedTabRef.current === 'rejected') {
            return row.rejectedAt;
          }

          return row.publicationDate;
        },
        onSort(sort) {
          if (selectedTabRef.current === 'queue') {
            return [{ postedAt: sort }];
          }

          if (selectedTabRef.current === 'rejected') {
            return [{ rejectedAt: sort }];
          }

          if (selectedTabRef.current === 'ready') {
            return [
              {
                publicationDate:
                  sort === 'DESC_NULLS_LAST'
                    ? 'ASC_NULLS_LAST'
                    : 'DESC_NULLS_LAST',
              },
            ];
          }

          return [
            {
              publicationDate: sort,
            },
          ];
        },
      },
      {
        field: 'publicationError',
        headerName: t('Error'),
        tabs: ['published'],
        width: 100,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: ' ',
        width: 90,
        selector: 'projectId',
        tabs: ['queue'],
        renderCell({ row }) {
          return (
            <Stack direction="row" spacing={0.5} sx={{ mr: 1 }}>
              <Tooltip title="Reject post">
                <IconButton
                  size="small"
                  color="error"
                  onClick={async () => {
                    await handleActionPress('reject', row);
                  }}
                >
                  <CloseBox sx={{ fontSize: 28 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Open post">
                <IconButton
                  size="small"
                  color="primary"
                  href={`/projects/${row.projectId}/posts/${row.id}`}
                >
                  <OpenInNew sx={{ fontSize: 26 }} />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      },
    ],
    [selectedTab],
  );

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      tableRef.current?.reload();
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <TablePageLayout
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4">{t('Posts')}</Typography>
        </Box>
      }
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<TextBoxPlusOutline />}
          onClick={() => {
            createEmptyPost();
          }}
        >
          {t('Add post')}
        </Button>
      }
    >
      <DataTableEx
        id="posts-table"
        ref={tableRef}
        source="post"
        columns={columns}
        rowHeight={80}
        deletable={false}
        editable={{
          icon: 'pencil',
        }}
        editPageUrl={`/projects/${selectedProjectId}/posts`}
        sortBy={{ field: 'publicationDate', sort: 'desc' }}
        persistStateMode="query"
        formTitle={() => 'Post content'}
        getRowId={(x) => `${selectedProjectId}-${x.id}`}
        tabsFilter={{
          tabs: [
            {
              id: 'queue',
              label: t('Queue'),
              filter: {
                projectId: { _eq: selectedProjectId },
                publicationDate: { _isNull: true },
                isRejected: { _eq: false },
              },
            },
            {
              id: 'rejected',
              label: t('Rejected'),
              filter: {
                projectId: { _eq: selectedProjectId },
                isRejected: { _eq: true },
              },
            },
            {
              id: 'ready',
              label: t('Ready'),
              filter: {
                isRejected: { _eq: false },
                projectId: { _eq: selectedProjectId },
                publicationDate: { _isNull: false },
                isPublished: { _eq: false },
              },
            },
            {
              id: 'published',
              label: t('Published'),
              filter: {
                projectId: { _eq: selectedProjectId },
                isPublished: { _eq: true },
              },
            },
          ],
          actionButton: (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {selectedTab === 'queue' && (
                <Box
                  sx={{
                    opacity: 0.6,
                    display: 'flex',
                    gap: 0.25,
                    flexDirection: 'column',
                    mr: 2,
                  }}
                >
                  {[
                    { text: t(' to reject 1-st post in a queue'), key: 'R' },
                    { text: t(' to view 1-st post in a queue'), key: 'K' },
                  ].map((x) => (
                    <Box
                      key={x.key}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          padding: '0px 6px',
                          height: 18,
                          minWidth: 18,
                          lineHeight: 0,
                          borderRadius: '6px',
                          backgroundColor: 'rgba(145, 158, 171, 0.16)',
                          color: 'rgb(99, 115, 129)',
                          fontSize: '11px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mr: 1,
                        }}
                      >
                        {x.key}
                      </Box>
                      <Typography variant="caption">{x.text}</Typography>
                    </Box>
                  ))}
                </Box>
              )}

              <Button variant="text" onClick={() => addStopword()}>
                {t('Add stopword')}
              </Button>
            </Box>
          ),
          tabsProps: {
            onChange(e, v) {
              setSelectedTab(v);
              selectedTabRef.current = v;
            },
          },
        }}
        selectProps={{
          filter: {
            projectId: { _eq: selectedProjectId },
          },
          onFetch(items) {
            firstEntityRef.current = items[0];
            return items;
          },
        }}
      />
    </TablePageLayout>
  );
}
